<template>
  <div class="afishionado container">
    <SpatialViewer
      src="https://res.cloudinary.com/blkmnt/image/upload/v1673452041/stores/onexrun/assets/TriangleFish_10.glb"
      background
      :settings="viewerSettings"
    />
    <div class="spatial-overlay" />
    <div class="afishionado__content">
      <img class="lockup" src="@/assets/images/events/afishionado-launch/lockup.svg">

      <div v-if="showCountdown" class="countdown large">
        {{ countdown }}
      </div>

      <div v-if="showClaimedCount" class="num-claimed">
        Total Claimed
        <br>
        <span class="large">
          <span v-if="!totalClaimed || !capTotal">
            <LoaderCover center transparentFull />
            /
          </span>
          <span v-else>{{ totalClaimed || '' }}/{{ capTotal || '' }}</span>
        </span>
      </div>

      <p>
        The AFishionado Crypto Cartel is a living, breathing collection of aquatic NFTs created by Naturel. Lawrence "Naturel" Atoigue is an American contemporary artist known for his futuristic blend of pop and cubism, which has captured the attention of celebrities such as Swizz Beatz, Rihanna, and LeBron James, among many others.
        <br><br>
        Genesis Gold is the first release in this exciting ongoing series. Catch your Genesis Angel Fish now.
      </p>
      <div class="drop-times">
        <div class="drop-time">
          <p class="bold">
            Genesis Card Holders: 24 Hour Pre-Release Access
          </p>
          <p>Jan 18, 2023 12PM EST</p>
        </div>
        <div class="drop-time">
          <p class="bold">
            Public Access: While Tokens Last
          </p>
          <p>Jan 19, 2023 12PM EST</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const { DateTime, Duration } = require('luxon')
export default {
  mounted () {
    this.claimsMetadataListener()
    let now = DateTime.local()
    const end = DateTime.fromISO('2023-01-19T12:00:00').setZone('America/New_York')

    this.counter = setInterval(() => {
      now = DateTime.local()
      const remaining = end.diff(now).toObject()
      this.countdown = Duration.fromObject(remaining).toFormat('dd:hh:mm:ss')
    }, 1000)
  },
  data () {
    return {
      targetType: 'angel-fish.genesis-gold',
      counter: null,
      countdown: 'DD:HH:MM:SS',

      viewerSettings: {
        autoRotate: false,
        backgroundColor: '0x000000',
        model: {
          scale: 0.66
        },
        animation: {
          enabled: true,
          index: 0
        },
        camera: {
          position: {
            x: -45,
            y: 0,
            z: 15
          },
          fov: 85,
          near: 0.05,
          far: 20
        },
        ground: {
          disabled: true,
          position: {
            y: -2 // -0.2 for card, -0.5 for box
          },
          mirror: {
            color: '0x777777'
          },
          overlay: {
            color: '0x000000',
            opacity: 0.8
          }
        },
        controls: {
          disableZoom: true,
          disablePan: true,
          minDistance: 0.8, // 0.2 for card, 0.8 for box
          maxDistance: 2, // 0.4 for card, 2 for box
          maxPolarAngle: 1
        },
        postProcessing: {
          enabled: true,
          gammaCorrection: {
            enabled: true
          },
          bloom: {
            enabled: true,
            threshold: 0.447,
            strength: 0.198,
            radius: 0.61
          }
        },
        lights: [
          {
            animate: true,
            color: '0x97feff',
            intensity: 1,
            distance: 50,
            animationRate: {
              x: 0.1,
              y: 0.2,
              z: 0.3
            },
            animationScale: {
              x: 30,
              y: 40,
              z: 30
            }
          },
          {
            animate: true,
            color: '0xffffff',
            intensity: 1,
            distance: 50,
            animationRate: {
              x: 0.1,
              y: 0.2,
              z: 0.3
            },
            animationScale: {
              x: 30,
              y: 40,
              z: 30
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['marketDataByType', 'claimsMetadata', 'globalSettings']),
    showCountdown () {
      return this.globalSettings?.featuredEvent?.showCountdown
    },
    showClaimedCount () {
      return this.globalSettings?.featuredEvent?.showClaimedCount
    },
    targetContract () {
      return this.globalSettings?.featuredEvent?.targetContract
    },
    totalClaimed () {
      if (!this.claimsMetadata) return 0
      return (this.claimsMetadata[this.targetType] || 0).toString().padStart(this.capTotal?.toString().length || 0, '0')
    },
    capTotal () {
      return this.marketDataByType(this.targetContract, this.targetType).cap
    }
  },
  methods: {
    ...mapActions(['claimsMetadataListener'])
  }
}
</script>

<style lang="scss" scoped>
.afishionado {
  text-align: center;
  font-family: 'Untitled Sans';
  font-size: $font-size-ml;
  max-width: 820px;

  &.container {
    padding: 0;
    margin: 0 auto;
  }

  @include media($bp-phone-l) {
    font-size: $font-size-s;
  }

  &__content {
    position: relative;
    pointer-events: none;

    @include media($bp-desktop-s) {
      pointer-events: all
    }
  }

  .spatial-overlay {
    @include fill-screen;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .lockup {
    width: 100%;
    max-width: 450px;
  }

  .drop-times {
    .drop-time {
      margin: $space-m 0;
    }
    .bold {
      font-weight: bold;
    }
  }
  .num-claimed {
    margin: $space-m 0;
  }
}
.large {
  position: relative;
  margin: $space-ml 0;
  font-size: $font-size-l;
  font-family: $font-body;

  @include media($bp-phone-l) {
    font-size: 36px;
  }
}
</style>
